import * as React from "react"
import PersonalBlogWrapper from "./style"
import {BlogPostDetailsWrapper, BlogDetailsContent} from '../../templates/templates.style'
import Posts from "./Posts"
import Banner from "./Banner"
import Sidebar from "../Sidebar"

type PersonalBlogProps = {}

const PersonalBlog: React.FunctionComponent<PersonalBlogProps> = ({
  ...props
}) => {
  return (
    <PersonalBlogWrapper {...props}>
      <BlogPostDetailsWrapper>
        <BlogDetailsContent>
          <Posts />
        </BlogDetailsContent>
        <Sidebar />
      </BlogPostDetailsWrapper>
    </PersonalBlogWrapper>
  )
}

export default PersonalBlog
